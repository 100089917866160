import React, { useEffect, useState } from "react"

import Styles from "./intro.module.css"
import Astronaut from "./astronaut"

const Byline = () => {
  return (
    <span style={{ zIndex: "2" }} className="text-white text-center lg:text-xl">
      We’re a forward-thinking, boutique creative and digital agency based in Auckland, New
      Zealand
    </span>
  )
}

const IntroLine = ({ fade }) => {
  return (
    <h2
      style={{ zIndex: "2", opacity: 1 - fade }}
      className={`${Styles.introLine} text-white font-bold text-3xl md:text-5xl xl:text-6xl text-center leading-tight`}
    >
      We deliver ethical branding and digital solutions for growing businesses
    </h2>
  )
}

const Intro = () => {
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", onScroll)
      return () => window.removeEventListener("scroll", onScroll)
    }
    return false
  }, [scrollTop])

  let fade = 0
  if (typeof window !== "undefined") {
    fade = scrollTop / (window.innerHeight / 1.5)
  }

  return (
    <div
      className={`${Styles.feature} flex justify-between items-center p-4 md:p-8 lg:p-12 flex-col`}
    >
      <span></span>
      <IntroLine fade={fade} />
      <Byline />
      <Astronaut fade={fade} />
    </div>
  )
}

export default Intro
