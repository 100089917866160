import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Intro from "../components/intro"

const IndexPage = ({ location }) => (
  <Layout location={location} isHome={true}>
    <SEO
      title="Creative and Digital Agency in Auckland, New Zealand"
      ogTitle={`Creative and Digital Agency in Auckland, New Zealand`}
    />
    <Intro />
  </Layout>
)

export default IndexPage
