import * as THREE from "three"
import React, { Suspense, useCallback, useRef } from "react"
import { Canvas } from "react-three-fiber"
import Character from "./character"
import Particles from "./Particles"
import Effects from "../post/Effects"
import { getMousePos } from "./utils"

function Astronaut({ fade }) {
  const d = 8.25
  let degContainer = { x: 0, y: 0 }

  if (typeof window !== "undefined") {
    degContainer = { x: window.innerWidth / 2, y: window.innerHeight / 2 }
  }

  let deg = useRef({ x: degContainer.x, y: degContainer.y })

  const mouse = useRef([0, 0])
  const onMouseMove = useCallback(({ clientX: x, clientY: y }) => {
    if (typeof window !== "undefined") {
      return (mouse.current = [
        x - window.innerWidth / 2,
        y - window.innerHeight / 2,
      ])
    }
  }, [])

  let deviceAspectRatio = 1

  if (typeof window !== "undefined") {
    deviceAspectRatio = window.devicePixelRatio
  }

  return (
    <div
      className="absolute top-0 left-0 h-screen w-full"
      style={{ zIndex: "1", opacity: 1 - fade }}
    >
      <Canvas
        // onMouseMove={e => (mouse.current = getMousePos(e))}
        shadowMap
        pixelRatio={deviceAspectRatio}
        camera={{ fov: 75, position: [0, 0, 30] }}
        onMouseMove={e => {
          onMouseMove(e)
          deg.current = getMousePos(e)
        }}
        onCreated={({ gl }) => {
          gl.toneMapping = THREE.Uncharted2ToneMapping
          gl.setClearColor(new THREE.Color("#030305"))
        }}
      >
        <fog attach="fog" args={[0xdfdfdf, 35, 65]} />
        <directionalLight
          position={[-8, 12, 8]}
          intensity={0.25}
          shadow-camera-left={d * -1}
          shadow-camera-bottom={d * -1}
          shadow-camera-right={d}
          shadow-camera-top={d}
          shadow-camera-near={0.1}
          shadow-camera-far={1500}
          castShadow
        />
        <Suspense fallback={null}>
          <Character mouse={deg} position={[0, 0, 0]} scale={[15, 15, 15]} />
        </Suspense>
        <Particles count={2500} mouse={mouse} />
        <Effects />
      </Canvas>
    </div>
  )
}

export default Astronaut
